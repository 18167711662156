<template>
  <div
    class="
      min-h-screen
      flex
      items-center
      justify-center
      bg-gray-50
      py-12
      px-4
      sm:px-6
      lg:px-8
      flex-col
    "
  >
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-16 w-auto" src="/img/logo.svg" alt="Workflow" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {{ $t("Reset your password") }}
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          {{ $t("Or") }}
          {{ " " }}
          <router-link
            to="/login"
            class="font-medium text-fuchsia-600 hover:text-fuchsia-500"
          >
            {{ $t("Sign in here") }}
          </router-link>
        </p>
      </div>
      <form class="space-y-6" @submit.prevent="ForgetPassword">
        <div>
          <label for="password" class="block text-sm font-medium text-gray-700">
            {{ $t("Password") }}
          </label>
          <div class="mt-1">
            <input
              id="password"
              name="password"
              type="password"
              required
              v-model="password"
              class="
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none
                focus:ring-fuchsia-500
                focus:border-fuchsia-500
                sm:text-sm
              "
            />
          </div>
        </div>
        <div>
          <label
            for="confirmed_password"
            class="block text-sm font-medium text-gray-700"
          >
            {{ $t("Confirm Password") }}
          </label>
          <div class="mt-1">
            <input
              id="confirmed_password"
              name="confirmed_password"
              type="password"
              required
              v-model="confirmed_password"
              class="
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none
                focus:ring-fuchsia-500
                focus:border-fuchsia-500
                sm:text-sm
              "
            />
          </div>
        </div>
        <div>
          <button
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-fuchsia-600
              hover:bg-fuchsia-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-fuchsia-500
            "
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon
                class="h-5 w-5 text-fuchsia-500 group-hover:text-fuchsia-400"
                aria-hidden="true"
              />
            </span>
            {{ $t("Send") }}
          </button>
        </div>
      </form>
    </div>
    <Footer />
  </div>
</template>

<script>
import { LockClosedIcon } from "@heroicons/vue/solid";
import Footer from "../components/footer.vue";
export default {
  components: {
    LockClosedIcon,
    Footer,
  },
  data() {
    return {
      password: null,
      confirmed_password: null,
    };
  },
  computed: {
    token() {
      return this.$route.query.token;
    },
    email() {
      return this.$route.query.email;
    },
  },
  methods: {
    ForgetPassword() {
      this.$store
        .dispatch("User/resetPassword", {
          password: this.password,
          confirmed_password: this.confirmed_password,
          email: this.email,
          token: this.token,
        })
        .then((res) => {
          this.$emit("showNotif", {
            type: res.type,
            msg: res.msg,
            showNotif: true,
          });
          if (res.type == "success") this.$router.push("/login");
        });
    },
  },
};
</script>
